import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import music from '../assets/music/jefri.aac'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'
import '../style/jefri.css'
import path from '../assets/img/jefri/path1.svg'
import path3 from '../assets/img/adnan/path3.svg'
import jef1 from '../assets/img/jefri/jef1.jpg'
import jef2 from '../assets/img/jefri/jef2.jpg'
import s1 from '../assets/img/jefri/1.webp'
import s2 from '../assets/img/jefri/2.webp'
import s3 from '../assets/img/jefri/3.webp'
import s4 from '../assets/img/jefri/4.webp'
import s5 from '../assets/img/jefri/5.webp'
import cincin from '../assets/img/jefri/cincin.svg'
import logoig from '../assets/img/jefri/logoig.svg'
import burung from '../assets/img/gold3/burung.svg'
import post from '../params/post'

import bg from '../assets/img/jefri/bg.webp'
import bg1 from '../assets/img/jefri/bg1.webp'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { cap } from '../params'




export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: ''
        }
    }
    useQuery = () => {
        return new URLSearchParams(this.props.location.search);

    }


    async componentDidMount() {


        try {




            AOS.init({
                // initialise with other settings
                duration: 1000
            });
            var countDownDate = new Date("10/18/2020").getTime();

            // Update the count down every 1 second
            var x = setInterval(() => {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                this.setState({
                    days: days, hours: hours, minutes: minutes, seconds: seconds
                })
            }, 1000);

        } catch (e) {
            console.log(e)
        }


    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();

        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }
    googleCalendar = () => {
        window.open(`https://calendar.google.com/calendar/r/eventedit?
  text=Pernikahan%20Jefry%20dan%20Wulan
  &details=Pernikahan%20Jefry%20dan%20Wulan
  &dates=20201018T090000/20201018T090000
  &ctz=Indonesia%2FEast_Java&title=Pernikahan%20Jefry%20dan%20Wulan
      `, '_blank')
    }
    handleSubmit = async () => {
        let local=localStorage.getItem('pesan')
        console.log(this.nama.current.value, this.alamat.current.value, this.pesan.current.value)
        let err = []
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.alamat.current.value == "") {
            err.push('Alamat tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "jefry-wulan", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }



    }

    render() {
        let query = this.useQuery().get('name');
        query = query ? cap(query) : ''
        let { days, hours, minutes, seconds, hide, hadir, err, submitted } = this.state
        console.log(submitted)
        return (
            <>
                <Helm
                    title='Undanganku - Jefry & Wulan'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={'https://i.ibb.co/nMCBGx4/1-300x200.png'}
                    url='https://undanganku.me/jefry-wulan'
                />
                <div id='jefri'>
                    <Container fluid id='g3-header' className='relative' style={{ backgroundImage: `url(${hide ? bg1 : bg})` }}>
                        <h2 className='roboto-slab'>
                            We Are Getting Married
                        </h2>
                        <h1 className='sacramento pt-5 pb-5'>
                            <div className='d-block'>Jefry </div>
                            <div className='d-block'>&</div>
                            <div className='d-block'> Wulan</div>
                        </h1>
                        <h2 className='roboto-slab'>
                            18 . 10 . 2020
                        </h2>


                        <Item>
                            {query ? (
                                <h2 className={`col-md-4 roboto-slab`}> Kepada : {query} </h2>

                            ) : false}</Item>
                        <Row className='justify-content-center'>
                            <div onClick={() => { this.play() }}
                               className={`col-md-4 button roboto-slab btn ${hide ? 'show' : 'hide'}`}>
                                Open Invitation
                            </div>
                        </Row>
                        <Col className={`absolute path ${hide ? 'hide' : 'show'}`} xs={12}>
                            <img className='img-fluid w-100' src={path} />
                        </Col>
                    </Container>
                    {!hide ? (
                        <>
                            <Container fluid>

                            </Container>
                            <Container fluid className='bg-green pt-3 pb-3' id='top'>
                                <Item>
                                    <Col xs={4} md={2} className='p-3 p-md-5'>
                                        <img src={cincin} className='img-fluid w-100' data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8}>
                                        <p className='italic roboto-slab c-white' data-aos="fade-right">
                                            “And of His signs is that He created for you from yourselves mates that youmay
                                            find tranquility in them; and He placed between you affection and mercy.Indeed
                  in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
                            </p>
                                    </Col>
                                </Item>
                            </Container>
                            <Container id='pasangan'>

                                <Item>
                                    <div className='p-3'>
                                        <Item>
                                            <Col xs={12} md={10} className='p-3 rounded' data-aos="fade-left">
                                                <p className=' tinos'>
                                                    Maha Suci Allah yang telah menciptakan Makhluk-Nya secara Berpasang-pasangan.
                                    <br></br><br></br>
                                    Ya Allah Perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri Kami
                                    </p>
                                                <Item>
                                                    <h1 className='sacramento p-3'>
                                                        The Groom
                                                    </h1>
                                                </Item>

                                                <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={jef1} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item>


                                                <Item>
                                                    <h1 className='c-main sansita p-3'>
                                                        Jefry Alan Budi Kusuma
                                        </h1>
                                                </Item>
                                                <Item>
                                                    <p className=' s-bold tinos'>Putra Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className=' tinos'>

                                                        Bpk. Ir. Darmawi (alm)<br />
                                            dan<br />
                                            Ibu Nurmala Dewi A.Md.Pd
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1}
                                                        onClick={() => {
                                                            window.open('http://instagram.com/jefri_alanbudikusuma')
                                                        }}>
                                                        <img src={logoig} className='img-fluid w-100 p-1 btn' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='c-main lobster p-3 larger'>
                                                        &
                                    </h1>
                                                </Item>
                                                <Item>
                                                    <h1 className='sacramento p-3'>
                                                        The Bride
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={jef2} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item>

                                                <Item>
                                                    <h1 className='c-main sansita p-3'>
                                                        Wulan Oktasari
                                        </h1>
                                                </Item>
                                                <Item>
                                                    <p className='s-bold tinos'>Putri Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className='tinos'>

                                                        Bpk Ismail Syamsudin<br />
                                            dan<br />
                                            Ibu Ely Sartika (almh)
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1} 
                                                        onClick={() => {
                                                            window.open('http://instagram.com/oktasarii')
                                                        }}>
                                                        <img
                                                            src={logoig} className='img-fluid w-100 p-1 btn' data-aos="fade-left" />
                                                    </Col>
                                                </Item>

                                            </Col>
                                        </Item>
                                    </div>
                                </Item>
                            </Container>
                            <Container fluid className='bg-white p-4' id='save'>
                                <h1 className='sacramento s-bold c-main' data-aos="fade-right">
                                    Save The Date
                                </h1>
                                <Item>
                                    <Col xs={10} md={6} className='p-3 rounded  s-bold c-main' data-aos="fade-left">
                                        <Item>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {days}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Days
                                        </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {hours}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Hours
                                    </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div >
                                                        {minutes}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Mins
                                        </span>
                                                </Item>
                                            </div>
                                            <div className='dot' >:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {seconds}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Secs
                                            </span>
                                                </Item>
                                            </div>
                                        </Item>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='p-3 border-pink rounded'>
                                        <Item>
                                            <Col xs={4} md={2} className='p-3'>
                                                <img src={burung} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <h1 className='sacramento s-bold mt-3 c-main f-mid' data-aos="fade-left">
                                            Akad Nikah
                                </h1>
                                        <p className='f-small poppins mt-4' data-aos="fade-left">
                                            <div className='s-bold c-main'>
                                                Minggu,18 Oktober 2020
                                </div>
                                            09.00 WIB s/d selesai<br />
                                            <div className='s-bold c-main'>
                                                Hotel Santika<br /></div>
Jl. Radial Palembang<br />
Bukit Kecil, Kota Palembang, Sumatera Selatan, 30132<br />

                                        </p>
                                        <Item>
                                            <Col onClick={() => { window.open('https://www.google.com/maps/dir//Hotel+Santika+Jl.+Radial+Palembang+Bukit+Kecil,+Kota+Palembang,+Sumatera+Selatan,+30132/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x2e3b75e161ef8739:0xd458693216f84015?sa=X&ved=2ahUKEwjwzZP7tKrsAhXTT30KHRPvCNsQ9RcwFXoECBEQBA') }}
                                                xs={8} md={4} className='button poppins c-white f-small rounded p-1 btn'  data-aos="zoom-in">
                                                Get Direction
                            </Col>
                                        </Item>
                                        <Item>
                                            <Col onClick={() => this.googleCalendar()} xs={8} md={4} className='button poppins c-white f-small rounded p-1 mt-3 btn' data-aos="zoom-in">
                                                Add to Google Calendar
                            </Col>

                                        </Item>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='border-pink poppins c-grey rounded p-2 f-small' data-aos="fade-right">
                                        Tanpa Mengurangi Rasa Hormat semua tamu harap menerapkan Protokol Kesehatan dengan menggunakan masker ketika Acara dan menggunakan hand Sanitizer yang telah disediakan.
                            <br />
                            Terima Kasih
                            </Col>
                                </Item>
                            </Container >
                            <Container fluid id='slider' className='bg-pink p-3 pb-5'>
                                <Item><Col xs={12} md={8}>
                                    <Slider slide={[s1, s2, s3, s4, s5]} />
                                </Col>
                                </Item>


                            </Container>
                            <Container className='bg-green' fluid id='form'>
                                <Item>

                                    <Col xs={10} md={6} className='bg-white rounded poppins mt-3 pt-4 pb-4' data-aos="zoom-in">
                                        <h1 className='sacramento c-main f-small send'>
                                            Send Your Whises
                        </h1>
                                        <form className="col-12 w-100 jefri">
                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                                            <input ref={this.alamat} type='text' className="col-12 w-100 text-center" placeholder="Alamat" name='alamat' />
                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                                            <Item>
                                                <div onClick={() => {
                                                    this.setState({ hadir: true })
                                                }
                                                }>
                                                    <input id="option" type="radio" checked={hadir ? true : false} />
                                                    <label ><span><span></span></span>Hadir</label>
                                                </div>
                                                <div onClick={() => {
                                                    this.setState({ hadir: false })
                                                }
                                                }>
                                                    <input id="option" type="radio" checked={hadir ? false : true} />
                                                    <label ><span><span></span></span>Tidak Hadir</label>
                                                </div>
                                            </Item>
                                            <Item>
                                                <Col xs={12} className=''>
                                                    {
                                                        submitted == true ? (
                                                            <Alert variant='success'>
                                                                Pesan anda sudah disampaikan
                                                            </Alert>) : (submitted === false ? (
                                                                <Alert variant='danger'>
                                                                    {
                                                                        err.map(val => {
                                                                            return (
                                                                                <li>{val}</li>
                                                                            )
                                                                        })
                                                                    }

                                                                </Alert>
                                                            ) : false)
                                                    }

                                                </Col>
                                            </Item>
                                            <Item>
                                                <div  className='col-6 button rounded' onClick={() => this.handleSubmit()}> Kirim </div>
                                            </Item>
                                        </form>

                                    </Col>
                                </Item>

                                <Item>
                                    <h1 className='sacramento c-white f-small send pt-3 pb-3'>
                                        Jefry & Wulan
                        </h1>
                                </Item>
                            </Container>
                            <Container fluid className='bg-green relative p-0' id='path'>
                                <img className='absolute img-fluid w-100 mx' src={path3} />
                            </Container>
                            <Container fluid className='bg-thite poppins pb-5' id='footer'>
                                <Item>
                                    <h1 data-aos="zoom-in" data-aos-duration="1000">
                                        Possible Wedding
              </h1>
                                </Item>
                                <Item>
                                    <h2 data-aos="zoom-in" data-aos-duration="1000">
                                        Digital Invitation
              </h2>
                                </Item>
                                <Item>
                                    <div className='col-3 col-lg-1 p-md-4'
                                        
                                        onClick={() => {
                                            window.open('http://instagram.com/possiblewedding')
                                        }}>
                                        <img src={logoig} className='img-fluid w-100 p-2 p-md-0 btn'
                                        />
                                    </div>
                                </Item>
                            </Container>
                        </>
                    ) : false}
                </div>
            </>
        )
    }
}